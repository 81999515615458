<template>
  <section>
    <v-card class="my-4 rounded-lg border" elevation="0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <span class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12">{{ $t("Question") }}</span>
        </div>
        <div>
          <span class="f13 font-weight-light"> {{ question.createDate | moment("from", "now") }} </span>
        </div>
      </v-card-title>
      
      <v-card-text>
        <p class="f14 text-justify" v-html="question.question"></p>
      </v-card-text>
    </v-card>
    <div>
      <v-sheet class="pa-6 f13" color="#f0f7ff" rounded="lg">{{ answerTitleHolder }}</v-sheet>
    </div>
    
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="mt-5">

        <v-textarea auto-grow outlined counter="1000" :rules="rules" required v-model="answer"></v-textarea>

      </div>

      <v-footer app color="transparent" class="mb-4">
        <v-container fluid>
          <v-btn class="f16" block elevation="0" rounded color="primary" large @click="validate()" :disabled="!valid">
            {{ $t("Submit") }}
          </v-btn>
        </v-container>
      </v-footer>
    </v-form>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";

export default {
  data() {
    return {
      question: {},
      userQuestionId: null,
      answer: "",
      valid: false,
      answerTitleHolder: this.$t('Enter your answer in the box below'),
      rules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters")
      ],
    };
  },
  methods: {
    getQuestionDetails(id) {
      apiService.getQuestionDetails(id)
        .then((response) => {
          this.question = response.data;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    getAnswerGuid() {
      apiService.getAnswerGuid()
        .then((response) => {
          if (response.data && response.data.description && response.data.description != "") {
            this.answerTitleHolder = response.data.description
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    submitForm() {
      var answerUserQuestionDTO = {
        answer: this.answer,
        doctorId: this.$store.getters.doctorId,
        userQuestionId: this.userQuestionId
      };
      apiService.answerToQuestion(answerUserQuestionDTO)
      .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t('Your answer was sent successfully'),
            confirmButtonText: this.$t('OK'),
          })
          .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.userQuestionId = this.$route.params.questionId;
    this.getQuestionDetails(this.userQuestionId);
    this.getAnswerGuid();
  },

}
</script>